*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui;
  }


  form{
    display: flex;
    place-content: center;
    margin-top: 2rem;
  }
  h2{
    color: gray;
  }
  input{
    width: 100px;
    font-size: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.3rem 0.6rem;
  }

  #setalarm{
    text-shadow: 2px 2px rgb(124, 160, 227);

  }
  #alarm_gif{
    height: 300px;
    
  }
  .timeInput{
    padding: 10px;
    background-color: rgb(224, 223, 220);
    border-radius: 5px;
  }
  #alarmat{
    color: white;
  }
  .setbtn{
    background-color:#006AEC ;
    color: white;
    font-family: sans-serif;
    font-size: 20px;
    width: 220px;
    height: 50px;
    margin-top: 25px;
    border: none;
    outline: none;
    border-radius: 15px;
    box-shadow: 1px 1px 10px rgba(182, 177, 177, 0.945);

  }
  .setbtn:hover{
transform: scale(1.1);
  }


 