.cardimg {
    height: 40px;
    animation: icons-animation 1s ease-in-out 1, rotate-animation 0.5s linear 2;
}

@keyframes rotate-animation {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.cardimg:hover {
    animation: none; /* Disable the icons-animation on hover */
}

.threeCard {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 0%, rgba(14,68,89,1) 0%, rgba(5,111,188,1) 0%, rgba(12,66,106,1) 0%, rgba(233,9,21,1) 0%, rgba(115,102,134,1) 0%, rgba(104,111,144,1) 0%, rgba(28,42,73,1) 0%, rgba(25,49,94,1) 13%, rgba(22,58,119,1) 26%, rgba(14,79,177,1) 40%, rgba(10,87,193,1) 54%, rgba(9,89,198,1) 76%, rgba(4,97,217,1) 92%, rgba(0,106,236,1) 100%, rgba(191,192,15,1) 100%, rgba(1,192,242,0.8436624649859944) 100%, rgba(9,192,232,1) 100%, rgba(204,192,0,1) 100%, rgba(72,138,177,1) 100%, rgba(241,11,24,1) 100%, rgba(102,23,26,1) 100%, rgba(216,20,20,1) 100%, rgba(13,70,111,1) 100%, rgba(25,119,169,1) 100%, rgba(78,141,119,1) 100%);
    padding: 35px;
    border-radius: 10px;
    position: relative;
}




.h1{
    font-size: 48px;
    font-weight: bold;
    color: white;
    animation: glow-animation 1s infinite alternate;
  }
  
  @keyframes glow-animation {
    0% {
        text-shadow: 0 0 5px #011004, 0 0 5px #00b3ff, 0 0 15px #ff00ea, 0 0 20px #0000ff;
    }
  
    50% {
        text-shadow: 0 0 10px #0a1202, 0 0 10px #00eeff, 0 0 30px #ff00f7, 0 0 40px #2600ff;
    }
  
    100% {
        text-shadow: 0 0 5px #55ff00, 0 0 5px #0095ff, 0 0 15px #ff00ff, 0 0 20px #0d00ff;
    }
  }